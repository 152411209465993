exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-analysis-process-js": () => import("./../../../src/pages/services/analysis-process.js" /* webpackChunkName: "component---src-pages-services-analysis-process-js" */),
  "component---src-pages-services-change-readiness-js": () => import("./../../../src/pages/services/change-readiness.js" /* webpackChunkName: "component---src-pages-services-change-readiness-js" */),
  "component---src-pages-services-small-business-js": () => import("./../../../src/pages/services/small-business.js" /* webpackChunkName: "component---src-pages-services-small-business-js" */),
  "component---src-pages-services-strategy-tom-js": () => import("./../../../src/pages/services/strategy-tom.js" /* webpackChunkName: "component---src-pages-services-strategy-tom-js" */),
  "component---src-pages-services-training-js": () => import("./../../../src/pages/services/training.js" /* webpackChunkName: "component---src-pages-services-training-js" */),
  "component---src-pages-services-wow-governance-js": () => import("./../../../src/pages/services/wow-governance.js" /* webpackChunkName: "component---src-pages-services-wow-governance-js" */)
}

